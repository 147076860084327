<template>
  <v-container>
    <v-row no-gutters>
      <v-col>
        <EvacuationsTable></EvacuationsTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EvacuationsTable from "./tables/EvacuationsTable.vue";
export default {
  name: "Evakuierungen",
  components: {
    EvacuationsTable,
  },
  data() {
    return {
      msg: "Evakuierungen",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
