<template>
  <!-- <div v-if="loadingState">loading...</div> -->
  <v-data-table
    :headers="headers"
    :items="items"
    sort-by="ID"
    :loading="loadingState"
    :search="search"
    multi-sort
    class="elevation-1"
    :footer-props="{
      showFirstLastPage: true,
      itemsPerPageText: 'Elemente pro Seite:',
      itemsPerPageAllText: 'Alle',
    }"
  >
    <template #[`footer.page-text`]="items">
      {{ items.pageStart }} - {{ items.pageStop }} / {{ items.itemsLength }}
    </template>
    <template #top>
      <v-toolbar flat dense short>
        <v-toolbar-title>{{ name }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Suche"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="primary" x-small fab @click="initialize">
          <v-icon medium>mdi-reload</v-icon>
        </v-btn>
        <v-divider vertical inset></v-divider>
        <v-dialog v-model="dialog" max-width="500px">
          <!-- <template #activator="{ on, attrs }">
            <v-btn color="primary" x-small fab v-bind="attrs" v-on="on">
              <v-icon medium>mdi-plus</v-icon>
            </v-btn>
          </template> -->

          <v-card>
            <v-card-title>
              <span v-if="editedItem !== -1" class="headline">{{
                formTitle
              }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-switch v-model="editedItem.finished">
                      <template #label>
                        <span v-if="editedItem.finished">Abgeschlossen</span>
                        <span v-else>Nicht abgeschlossen</span>
                      </template></v-switch
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="editedItem.reason"
                      label="Grund"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close">Abbrechen</v-btn>
              <v-btn color="primary" text @click="save">Speichern</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template #[`item.CreatedAt`]="{ item }">
      <!-- eslint-disable-next-line vue/no-deprecated-filter -->
      {{ item.CreatedAt | formatTimeString }}
    </template>
    <template #[`item.finishedat.Time`]="{ item }">
      <!-- eslint-disable-next-line vue/no-deprecated-filter -->
      {{ item.finishedat | formatTimeString }}
    </template>
    <template #[`item.finished`]="{ item }">
      <v-switch v-model="item.finished" disabled height="0px"></v-switch>
    </template>
    <template #[`item.actions`]="{ item }">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <router-link
            tag="a"
            :to="{ name: 'Evacuation', params: { evacuationID: item.ID } }"
            style="text-decoration: none"
          >
            <v-icon medium class="mr-3" v-on="on"> mdi-open-in-app </v-icon>
          </router-link> </template
        ><span>Evakuierung öffnen</span>
      </v-tooltip>
      <v-tooltip v-if="!item.finished" bottom>
        <template #activator="{ on }">
          <v-icon medium class="mr-3" v-on="on" @click="finishEvacuation(item)">
            mdi-check
          </v-icon> </template
        ><span>Evakuierung abschließen</span>
      </v-tooltip>
      <v-icon medium class="mr-3" @click="editItem(item)">mdi-pencil</v-icon>
    </template>
    <template #no-data>
      <v-btn color="primary" @click="initialize">Aktualisieren</v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  name: "EvacuationsTable",
  data: () => ({
    name: "Evakuierungen",
    search: "",
    dialog: false,
    headers: [
      { text: "ID", align: "start", value: "ID" },
      { text: "Erstellzeit", value: "CreatedAt" },
      { text: "Abschlusszeit", value: "finishedat.Time" },
      { text: "Grund", value: "reason" },
      { text: "Abgeschlossen", value: "finished" },
      { text: "Aktionen", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {
      companyid: null,
      name: "",
      email: "",
    },
  }),
  computed: {
    ...mapGetters("evacuations", {
      loadingState: "loadingState",
      items: "items",
    }),
    formTitle() {
      return this.editedIndex === -1
        ? "Neu"
        : "Bearbeiten" + " | ID: " + this.editedItem.ID;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch("evacuations/updateData");
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    async deleteItem(item) {
      const index = this.items.indexOf(item);
      await Swal.fire({
        title: "Sicher, dass dieses Element gelöscht werden soll?",
        showDenyButton: true,
        confirmButtonText: `Löschen`,
        denyButtonText: `Abbrechen`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("evacuations/delete", item);
          this.items.splice(index, 1);
          Swal.fire("Gelöscht!", "", "success");
        } else if (result.isDenied) {
          Swal.fire("Das Element wurde nicht gelöscht.", "", "info");
          return true;
        }
      });
    },
    async finishEvacuation(item) {
      const index = this.items.indexOf(item);
      console.log("finishEvacuation clicked: ", index, item);
      await Swal.fire({
        title:
          "Sicher, dass diese Evakuierung als abgeschlossen markiert werden soll?",
        showDenyButton: true,
        confirmButtonText: `Abschließen`,
        denyButtonText: `Abbrechen`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("evacuations/finish", item);
          item.finished = true;
          // Ersetzt 1 item an Stelle index
          this.items.splice(index, 1, item);

          Swal.fire("Evakuierung abgeschlossen!", "", "success");
          this.$store.dispatch("evacuations/updateData");
        } else if (result.isDenied) {
          Swal.fire(
            "Die Evakuierung wurde nicht als Abgeschlossen markiert.",
            "",
            "info"
          );
          return true;
        }
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem);
        this.$store.dispatch("evacuations/update", this.editedItem);
        this.$store.dispatch("evacuations/updateData");
      } else {
        this.$store.dispatch("evacuations/add", this.editedItem);
      }
      this.close();
    },
  },
};
</script>
